import * as React from "react";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";

const title = "Low Impact HIIT";
const url = "https://www.youtube.com/watch?v=K8UVjOoLN3Y";

export default function FreeWorkout() {
  return (
    <div className="signup-free-workout">
      <div className="text-container">
        <div className="title-container">
          <h3 className="slogan">Then, you'll get</h3>
          <h2 className="title">Moving!</h2>
        </div>
        <p className="description">
          My motto has ALWAYS been &quot;You're lapping everyone that's on the
          couch!&quot;. Any movement is healthy movement, am I right?! My
          workouts are just 30 minutes and suitable for ALL FITNESS LEVELS!
          That's just 2% of your day! So there's no excuses when it comes to
          making #BALANCE part of your daily lifestyle! Give your first workout
          a try!
        </p>
      </div>

      <div className="video-container">
        <EmbedYouTubeVideo {...{ title, url }} />
      </div>
    </div>
  );
}
